import * as React from "react";
import { graphql } from "gatsby";
import Image from "gatsby-plugin-sanity-image";
import Layout from "../components/layout";

// markup
const PhotosPage = (data) => {
  return (
    <Layout>
      {data.data.allSanityPhoto.nodes
        //  .filter((photo) => drink.drinktype.includes("cocktail"))
        .map(
          (photo) =>
            photo.image && <Image {...photo.image} alt={photo.description} />
        )}
    </Layout>
  );
};

export const query = graphql`
  query PhotosQuery {
    allSanityPhoto {
      nodes {
        description
        category
        image {
          ...ImageWithPreview
        }
      }
    }
  }
`;

export default PhotosPage;
